@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
.card-media {
  padding-top: 56%;
  height: 0;
}

.card-content {
  flex-grow: 1;
}

.App {
  /* padding: 25px; */
}

.main-login {
  width: auto;
  align-items: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.colorPrimary {
  color: #2196f3;
}

.login-paper {
  display: flex;
  width: 500px;
  padding: 20px;
  align-items: center;
  margin: auto;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
  font-family: "Apercu";
  src: local("Apercu"), url(/static/media/apercu-regular.cf219342.woff) format("woff");
}

@font-face {
  font-family: "Domaine Display";
  src: local("Domaine Display"),
    url(/static/media/domaine-display-web-regular.e6d4836c.woff) format("woff");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(/static/media/Roboto-Regular.8a36205b.ttf) format("ttf");
}

