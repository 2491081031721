.card-media {
  padding-top: 56%;
  height: 0;
}

.card-content {
  flex-grow: 1;
}

.App {
  /* padding: 25px; */
}

.main-login {
  width: auto;
  align-items: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.colorPrimary {
  color: #2196f3;
}

.login-paper {
  display: flex;
  width: 500px;
  padding: 20px;
  align-items: center;
  margin: auto;
}

html {
  scroll-behavior: smooth;
}
