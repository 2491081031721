@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
  font-family: "Apercu";
  src: local("Apercu"), url(./fonts/Apercu/apercu-regular.woff) format("woff");
}

@font-face {
  font-family: "Domaine Display";
  src: local("Domaine Display"),
    url(./fonts/Domaine/domaine-display-web-regular.woff) format("woff");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(./fonts/Roboto/Roboto-Regular.ttf) format("ttf");
}
